<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card class="pb-4">
        <v-card-title>
          <span class="text-h5"> {{ $t("contactUs.contactUs") }} </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  ref="name"
                  v-model="name"
                  required
                  counter="32"
                  oninput="if(this.value.length >= 32) this.value = this.value.substr(0, 31);"
                  :rules="[rules.required, rules.minLength]"
                  :label="$t('contactUs.yourName')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  ref="lastName"
                  v-model="lastName"
                  counter="32"
                  oninput="if(this.value.length >= 32) this.value = this.value.substr(0, 31);"
                  :label="$t('contactUs.yourLastname')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="fromEmail"
                  v-model="fromEmail"
                  counter="32"
                  oninput="if(this.value.length >= 32) this.value = this.value.substr(0, 31);"
                  :rules="[rules.email, rules.required]"
                  :label="$t('contactUs.yourEmail')"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  ref="category"
                  v-model="category"
                  :items="categoryItems"
                  item-text="description"
                  item-value="id"
                  value="id"
                  :label="$t('contactUs.talkAbout')"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  ref="message"
                  v-model="message"
                  outlined
                  required
                  counter="400"
                  oninput="if(this.value.length >= 400) this.value = this.value.substr(0, 399);"
                  :rules="[rules.required, rules.counter]"
                  :label="$t('contactUs.sendMessage')"
                />
              </v-col>
            </v-row>
          </v-container>
          <small> {{ $t("contactUs.required") }} </small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="close"
          >
            {{ $t("common.exit") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click="sendEmail()"
          >
            {{ $t("common.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <OverlayBar :overlay="overlay" />
    </v-dialog>
  </v-row>
</template>

<script>
import OverlayBar from "../OverlayBar.vue";
export default {
    components: {
        OverlayBar,
    },
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        selectedCategory: {
            type: Number,
            default: 0,
        },
        message: {
            type: String,
            default: "",
        },
        dataFix: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            overlay: false,
            name: "",
            lastName: "",
            fromEmail: "",
            category: this.selectedCategory,
            internalDialog: this.dialog,
            categoryItems: [
                {
                    id: 1,
                    type: "contact",
                    description: this.$t("contactUs.contactOptions.contact"),
                },
                {
                    id: 2,
                    type: "data-correction",
                    description: this.$t("contactUs.contactOptions.dataFix"),
                },
                {
                    id: 3,
                    type: "doubts",
                    description: this.$t("contactUs.contactOptions.doubts"),
                },
                {
                    id: 4,
                    type: "suggestions",
                    description: this.$t("contactUs.contactOptions.suggestions"),
                },
                {
                    id: 5,
                    type: "addData",
                    description: this.$t("contactUs.contactOptions.addData"),
                },
            ],
            //message: "",
            formHasErrors: false,
            rules: {
                required: (value) => !!value || this.$t("common.rules.requiredLabel"),
                minLength: (value) => value.length >= 3 || this.$t("common.rules.minLength", { length: 3 }),
                counter: (value) => value.length >= 20 ||this.$t("common.rules.minLength", { length: 20 }),
                email: (value) => {
                    const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || this.$t("common.rules.invalidEmail");
                },
            },
        };
    },

    computed: {
        form() {
            return {
                name: this.name,
                fromEmail: this.fromEmail,
                category: this.category,
                message : this.message
            };
        },
    },
    methods: {
        async sendEmail() {
            this.validateOnSubmit();
            if (!this.formHasErrors) {
                this.overlay = true;

                const payload = {
                    name: this.name,
                    lastName: this.lastName,
                    email: this.fromEmail,
                    message: " /// " + this.dataFix + " /// " + this.message, //Alterar isso para uma propriedade do email de contato
                    category: this.category,
                };

                var alert = null;

                try {
                    await this.$http.post("emailcontacts", payload);

                    alert = {
                        active: true,
                        message: this.$t("emailComponent.messages.success"),
                        color: "success",
                        icon: "mdi-check",
                    };
                } catch (error) {
                    alert = {
                        active: true,
                        message: this.$t("emailComponent.messages.error"),
                        color: "error",
                        icon: "mdi-information-variant",
                    };
                }

                if (alert) {
                    this.$store.dispatch("alert/updateAlert", alert);
                }

                this.internalDialog = false;
                this.$emit("close");
            }

            this.overlay = false;
        },
        close() {
            Object.keys(this.form).forEach((f) => {
                this.$refs[f].resetValidation(true);
            });

            this.internalDialog = false;
            this.$emit("close");
        },
        validateOnSubmit() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((field) => {
                if (!this.form[field]) {
                    this.formHasErrors = true;
                }

                if(field === 'name' && this.form[field].length < 3){
                    this.formHasErrors = true;
                }

                if(field === 'message' && this.form[field].length < 20){
                    this.formHasErrors = true;
                }

                this.$refs[field].validate(true);
            });
        },
    },
};
</script>

<style>
</style>