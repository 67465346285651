import i18n from '../plugins/i18n'

export default {
    namespaced: true,
    state: {
        actualLocale: "pt-br"
    },
    getters: {
        getActualLocate(state) {
            return state.actualLocale
        }
    },
    actions: {
        setActualLocate({ commit }, actualLocale) {
            commit("SET_ACTUAL_LOCATION", actualLocale)
        }
    },

    mutations: {
        SET_ACTUAL_LOCATION(state, actualLocale) {
            const locale = actualLocale ? actualLocale : state.actualLocale

            state.actualLocale = locale

            i18n.locale = locale

            localStorage.setItem("_horto_sanctorum_actuallocale", locale)

        }
    },
}