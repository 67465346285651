export default {
    namespaced: true,
    state: {
        alert: {
            active: false,
            message: "",
            color: "",
            icon: "",
        },
    },
    getters: {
        getAlert(state) {
            return state.alert
        }
    },
    actions: {
        updateAlert({ commit }, { active, message, color, icon }) {
            commit("UPDATE_ALERT", { active, message, color, icon })
        }
    },

    mutations: {
        UPDATE_ALERT(state, { active, message, color, icon }) {
            state.alert.active = active;
            state.alert.message = message;
            state.alert.color = color;
            state.alert.icon = icon;
        }
    },
}