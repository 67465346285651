<template>
  <v-dialog
    v-model="dialogDonate"
    persistent
    width="650"
    height="450"
  >
    <v-card>
      <v-toolbar>
        <v-card-title> {{ $t("donateModal.title") }} </v-card-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-8">
        {{ $t("donateModal.mobileDescription") }}
      </v-card-text>
      <v-card-actions :class="!isMobileDevice() ? 'pl-6 pb-8' : 'pl-6'">
        <v-col
          :cols="!isMobileDevice() ? 8 : 12"
          class="d-flex"
        >
          <v-text-field
            outlined
            :value="donatePageUrl"
            label="URL"
          />
          <v-btn
            outlined
            height="55"
            class="copy-button"
            color="info"
            @click="copyUrl()"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-card-actions>
      <v-card-text class="pt-0">
        {{ $t("donateModal.mobileRedirectDescription") }}
      </v-card-text>
      <v-card-actions class="align-center justify-center pb-8">
        <v-btn
          outlined
          height="55"
          color="success"
          @click="redirectToUrl()"
        >
          {{ $t("donateModal.mobileButtonText") }}
          <v-icon class="pl-2">
            mdi-arrow-top-right-bold-box-outline
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    props: {
        dialogDonate: {
            type: Boolean,
            default: false,
        },
        donatePageUrl: {
            type: String,
            default: "https://www.paypal.com/donate/?hosted_button_id=75W6HZAV4PCRN",
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        async copyUrl() {
            const valueToCopy = this.donatePageUrl;
            try {
                await navigator.clipboard.writeText(valueToCopy);
            } catch (error) {
                console.error("Falha ao copiar para a área de transferência:", error);
            }
        },
        redirectToUrl() {
            const url = this.donatePageUrl;
            window.open(url, "_blank");
            this.close();
        },
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
</style>