<template>
  <v-overlay :value="overlay">
    <v-progress-circular
      aria-label="Loading..."
      color="primary"
      indeterminate
      size="64"
    />
  </v-overlay>
</template>
<script>
export default {
    props: {
        overlay: {
            type: Boolean,
            default: false,
        },
    },
    methods: {},
};
</script>