

export default {
    namespaced: true,
    state: {
        searchName: ""
    },
    getters: {
        getQuickSearchName(state) {
            return state.searchName
        }
    },
    actions: {
        setQuickSearchName({ commit }, searchName) {
            commit("SET_QUICK_SEARCH_NAME", searchName)
        }
    },

    mutations: {
        SET_QUICK_SEARCH_NAME(state, searchName) {
            state.searchName = searchName
        }
    },
}