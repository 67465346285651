import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import MaryIcon from '../components/Icons/MaryIcon'

Vue.use(Vuetify);

const isDarkTheme = localStorage.getItem("_horto_sanctorum_darktheme") === 'true';

export default new Vuetify({
    theme: {
        dark: isDarkTheme,
        themes: {
            light: {
                navbar: "#F2E5C4",
                primary: "#6C4F42",
                secondary: "#66534D",
                tertiary: "#A69387",
                quaternary: "#5F4C49",
                accent: "#DAB965",
                error: "#FF0000",
                info: "#0056B3",
                success: "#008000",
                warning: "#FFA500"
            },
            dark: {
                navbar: "#F2E5C4",//"#343A40"
                primary: "#A7B8BE",
                secondary: "#9A9A9A",
                tertiary: "#555555",
                quaternary: "#4A4D52",
                accent: "#DAB965",
                error: "#FF8181",
                info: "#66AFFF",
                success: "#6ACD8C",
                warning: "#FFA500"
            }
        },
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        values: {
            maryIcon: {
                component: MaryIcon,
            }
        }
    },
});