import axios from 'axios'
import router from '../router/index'
import store from '../store'
import i18n from '../plugins/i18n'

const baseURL ='https://api.hortus-sanctorum.com/v1/hortosanctorum/'
//const baseURL = 'https://localhost:7090/v1/hortosanctorum/';
const tokenKey = 'hortus-token'

const api = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: {
        'Authorization': 'teste-header-authorization',
        'x-language-value': getUserLocale(),
        'Referrer-Policy': 'no-referrer',
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
        'X-Frame-Options': 'deny'
    }
});

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        console.error('request error:', error.response);

        if (error.response && error.response.status === 401) {
            localStorage.removeItem(tokenKey)
            originalRequest._retry = true;
            const token = await getToken();
            localStorage.setItem(tokenKey, token);
            originalRequest.headers.Authorization = token
            return api(originalRequest);
        }

        if (router.currentRoute.path !== '/') {
            router.push('/error')
        }

        throw error;
    }
);



api.interceptors.request.use(async (config) => {
    let token = localStorage.getItem(tokenKey);

    if (!token) {
        try {
            token = await getToken();
            localStorage.setItem(tokenKey, token);
        } catch (error) {
            console.error('token-request-error:', error);
            return Promise.reject(error);
        }
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.request.use(async (config) => {
    return config;
});

function getUserLocale() {
    const userLanguage = localStorage.getItem("_horto_sanctorum_actuallocale") ||
        store.getters['internationalization/getActualLocate'] ||
        i18n.locale ||
        navigator.language ||
        navigator.userLanguage;

    var formattedLanguage

    switch (userLanguage) {
    case "de-de":
        formattedLanguage = "DE"
        break;
    case "en-us":
        formattedLanguage = "EN"
        break;
    case "es-es":
        formattedLanguage = "ES"
        break;
    case "fr-fr":
        formattedLanguage = "FR"
        break;
    case "it-ele":
        formattedLanguage = "IT"
        break;
    case "la-la":
        formattedLanguage = "LA"
        break;
    case "pt-br":
        formattedLanguage = "BR"
        break;
    default:
        formattedLanguage = "BR"
        break;
    }

    return formattedLanguage
}

async function getToken(){
    const response = await axios.get(baseURL + 'auth/get-token');
    return response.data.token;
}

export default api