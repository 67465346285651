import Vue from 'vue'
import Vuex from 'vuex'
import internationalization from './internationalization'
import theme from './theme'
import search from './search'
import alert from './alert'
import pope from './pope'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        namespaced: true,
        internationalization,
        theme,
        search,
        alert,
        pope
    }
})


