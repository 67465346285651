import router from '../router/index'

export default {
    namespaced: true,
    state: {
        popePersonId: null,
        actualPage: 1
    },
    getters: {
        getPopePersonId(state) {

            router.beforeEach((to, from, next) => {

                if (!from.path.includes("popetimeline") && !from.path.includes("description") && !from.path.includes("search")) {
                    state.popePersonId = null
                    state.actualPage = 1
                }

                next()
            })

            return state.popePersonId
        },
        getActualPage(state) {
            return state.actualPage
        }
    },
    actions: {
        setPopePersonId({ commit }, popePersonId) {
            commit("SET_POPE_PERSON_ID", popePersonId)
        },
        setActualPage({ commit }, actualPage) {
            commit("SET_ACTUAL_PAGE", actualPage)
        },
        clearState({ commit }) {
            commit("CLEAR_STATE")
        }
    },

    mutations: {
        SET_POPE_PERSON_ID(state, popePersonId) {
            state.popePersonId = popePersonId
        },
        SET_ACTUAL_PAGE(state, actualPage) {
            state.actualPage = actualPage
        },
        CLEAR_STATE(state) {
            state.popePersonId = null
        }
    },
}