import Vuetify from '../plugins/vuetify'

export default {
    namespaced: true,
    state: {
        theme: "light"
    },
    getters: {
        // getActualTheme(state) {
        //     //var theme = localStorage.getItem("_horto_sanctorum_darktheme") || state.theme
        //     //Vuetify.framework.theme.dark
        //     return localStorage.getItem("_horto_sanctorum_darktheme") || state.theme
        // }
    },
    actions: {
        setActualTheme({ commit }, actualTheme) {
            commit("SET_ACTUAL_THEME", actualTheme)
        }
    },

    mutations: {
        SET_ACTUAL_THEME(state, actualTheme) {
            state.actualTheme = actualTheme
            Vuetify.framework.theme.dark = !Vuetify.framework.theme.dark
            localStorage.setItem("_horto_sanctorum_darktheme", Vuetify.framework.theme.isDark)
        }
    },
}