import Vue from 'vue'
import VueRouter from 'vue-router'
//import scrollMixin from '../mixin/generalMixin';

Vue.use(VueRouter)
//Vue.mixin(scrollMixin);


const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "homePage" */ '../components/HomePage.vue')
    },
    {
        path: '/popetimeline/:elementId?',
        name: 'PopeTimeLine',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "popeTimeLine" */ '../components/Popes/PopeTimeLine.vue'),
        props: true
    },
    {
        path: '/popelist',
        name: 'PopeList',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "popeList" */ '../components/Popes/PopeList.vue'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "about" */ '../components/AboutPage.vue')
    },
    {
        path: '/description/:id',
        name: 'Description',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "description" */ '../components/DescriptionPage.vue'),
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "searchPage" */ '../components/SearchPage.vue'),
    },
    {
        path: '/marytitles',
        name: 'WorldMaryTitles',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "worldMaryTitles" */ '../components/WorldMaryTitles.vue'),
    },
    {
        path: '/bloglist',
        name: 'BlogList',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "blogList" */ '../components/Blog/BlogList.vue'),
    },
    {
        path: '/blog/:friendlyUrl',
        name: 'BlogPage',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "blogPage" */ '../components/Blog/BlogPage.vue'),
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "errorPage" */ '../components/ErrorPage.vue'),
    },
    {
        path: '*',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "errorPage" */ '../components/HomePage.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
