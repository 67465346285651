import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import GlobalMethods from './plugins/globalMethods.js'
import api from './configs/api'
import moment from 'moment';
import VueMoment from 'vue-moment'
import Meta from 'vue-meta'

//const currentVersion = 'v2';

//validateApplicationVersion();

Vue.prototype.$http = api
Vue.config.productionTip = false
Vue.mixin(GlobalMethods)

const userLanguage = getUserLocale();
moment.locale(userLanguage);
Vue.use(VueMoment, { moment });
Vue.use(Meta);

new Vue({
    created() {
        window.addEventListener('resize', this.checkScreenResolution);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenResolution);
    },
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')


function getUserLocale() {
    const userLanguage = localStorage.getItem("_horto_sanctorum_actuallocale") ||
    store.getters['internationalization/getActualLocate'] ||
    i18n.locale ||
    navigator.language ||
    navigator.userLanguage;

    return userLanguage
}

// function validateApplicationVersion(){
//     document.addEventListener('DOMContentLoaded', () => {
//         const storedVersion = localStorage.getItem('version');

//         if (!storedVersion || storedVersion !== currentVersion) {
//             clearBrowserData();
//             localStorage.setItem('version', currentVersion);
//             //window.location.reload();
//         }
//     });
// }

// function clearBrowserData() {
//     sessionStorage.clear();
//     document.cookie.split(';').forEach((c) => {
//         document.cookie = c
//             .replace(/^ +/, '')
//             .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
//     });
// }