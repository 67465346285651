<template>
  <v-app>
    <v-app-bar
      fixed
      light
      color="navbar"
    >
      <v-app-bar-nav-icon
        aria-label="To Back Page"
        variant="text"
        @click.stop="drawer = !drawer"
      />
      <v-btn
        v-if="!isMobileDevice()"
        aria-label="To Back Page"
        text
        icon
        class="mr-2"
        @click="toBackPage()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-img
        position="left"
        contain
        class="clickable-logo"
        max-height="52"
        :max-width="isMobileDevice() ? '40%' : '100%'"
        :src="require('@/assets/candle-complete-header.png')"
        @click="toHomePage()"
      />
      <v-spacer :class="!isMobileDevice() ? 'spacer-padding' : ''" />
      <v-btn
        v-if="isMobileDevice()"
        :aria-label="$t('search.search')"
        icon
        @click="redirectToSearch()"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-text-field
        v-else
        v-model="search"
        :placeholder="$t('search.search')"
        prepend-inner-icon="mdi-magnify"
        class="expanding-search mt-6"
        :class="{
          closed: searchClosed && (!search || $route.name === 'SearchPage'),
        }"
        :outlined="!searchClosed"
        :disabled="$route.name === 'SearchPage'"
        dense
        single-line
        solo
        flat
        clearable
        background-color="transparent"
        @focus="searchClosed = false"
        @blur="searchClosed = true"
        @keydown.enter="quickSearch()"
      />
      <v-btn
        v-if="!isMobileDevice()"
        aria-label="About"
        :to="'/about'"
        text
        icon
      >
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
      <v-menu min-width="200">
        <template #activator="{ on, attrs }">
          <v-btn
            aria-label="Language Select"
            icon
            v-bind="attrs"
            class="pa-0 ma-4"
            v-on="on"
          >
            <v-img
              cover
              aspect-ratio="1"
              max-height="55"
              max-width="40"
              class="mr-0 rounded-circle"
              :src="require(`@/assets/language-flags/${getActualLocale}.png`)"
            />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            :value="locale"
            @click="setLocale(locale, true)"
          >
            <v-list-item-title
              v-model="$i18n.locale"
              class="d-flex"
            >
              <v-img
                :src="require(`@/assets/language-flags/${locale}.png`)"
                max-height="65"
                max-width="40"
                class="mr-4"
              />
              {{ $t(`common.locales.${locale}`) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        aria-label="Set Theme"
        icon
        @click="setTheme()"
      >
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      location="bottom"
      temporary
      fixed
    >
      <v-list>
        <v-list-item
          v-for="item in itemsFull"
          :key="item.value"
          link
          :to="item.path"
        >
          <v-icon class="pr-4">
            {{ item.icon }}
          </v-icon>
          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="main-app">
      <v-alert
        v-if="getAlert.active"
        class="mx-4"
        border="top"
        reverse
        :color="getAlert.color"
        :icon="getAlert.icon"
        text
        transition="scroll-y-transition"
        @enter="scrollToTop()"
      >
        {{ getAlert.message }}
        <v-progress-linear
          v-model="bufferValue"
          aria-label="Loading..."
          class="mt-3"
          :color="getAlert.color"
          :buffer-value="bufferValue"
        />
      </v-alert>
      <router-view />
    </v-main>
    <FloatButton />
  </v-app>
</template>

<script>
import FloatButton from "./components/FloatButton.vue";

export default {
    name: "App",

    components: {
        FloatButton,
    },

    data() {
        return {
            drawer: false,
            group: null,
            search: null,
            searchClosed: true,
            bufferValue: 1,
        };
    },
    computed: {
        getActualLocale() {
            return this.$store.getters["internationalization/getActualLocate"];
        },
        getAlert() {
            const alert = this.$store.getters["alert/getAlert"];
            if (alert.active) {
                this.scrollToTop();
            }
            return alert;
        },
        itemsFull() {
            const translatedItems = [
                {
                    id: 1,
                    icon: "mdi-home-outline",
                    title: this.$t("common.menu.home"),
                    value: "home",
                    path: "/",
                },
                {
                    id: 2,
                    icon: "mdi-timeline-text-outline",
                    title: this.$t("common.menu.popeTimeline"),
                    value: "list",
                    path: "/popetimeline",
                },
                {
                    id: 3,
                    icon: "mdi-account-search-outline",
                    title: this.$t("common.menu.search"),
                    value: "search",
                    path: "/search",
                },
                {
                    id: 4,
                    icon: "mdi-script-text-outline",
                    title: this.$t("common.menu.blogPage"),
                    value: "bloglist",
                    path: "/bloglist",
                },
                {
                    id: 5,
                    icon: "$maryIcon",
                    title: this.$t("common.menu.marytitles"),
                    value: "marytitles",
                    path: "/marytitles",
                },
                {
                    id: 6,
                    icon: "mdi-information-variant",
                    title: this.$t("common.menu.about"),
                    value: "about",
                    path: "/about",
                },
            ];

            return translatedItems;
        },
    },
    watch: {
        group() {
            this.drawer = false;
        },
    },
    async beforeMount() {
        await this.setLocale(localStorage.getItem("_horto_sanctorum_actuallocale"));
    },
    methods: {
        async setLocale(locale, selectedByMenu) {
            await this.$store.dispatch(
                "internationalization/setActualLocate",
                locale
            );
            if (selectedByMenu) {
                window.location.reload();
            }
        },
        async setTheme() {
            await this.$store.dispatch("theme/setActualTheme");
        },
        async quickSearch() {
            await this.$store.dispatch("search/setQuickSearchName", this.search);
            this.search = null;
            this.$router.push({ path: "/search" });
        },
        redirectToSearch() {
            this.$router.push({ path: "/search" });
        },
        scrollToTop() {
            this.$nextTick(() => {
                window.scrollTo(0, 0);
            });

            const increment = 100 / 50;

            const timer = setInterval(() => {
                this.bufferValue += increment;

                if (this.bufferValue >= 100) {
                    this.getAlert.active = false;
                    this.bufferValue = 0;
                    clearInterval(timer);
                }
            }, 100);
        },
        toBackPage() {
            const baseRoute = { path: "" };
            const actualRoute = this.$router.currentRoute;

            if (actualRoute.path !== baseRoute.path && actualRoute.path !== "/") {
                this.$router.back();
            } else {
                return;
            }
        },
        toHomePage() {
            const baseRoute = { path: "" };
            const actualRoute = this.$router.currentRoute;

            if (actualRoute.path !== baseRoute.path && actualRoute.path !== "/") {
                this.$router.push("/");
            }
        },
    },
};
</script>
<style>
[class*="v-"] {
  word-break: break-word;
  white-space: normal;
}
</style>
<style scoped>
.main-app {
  margin-top: 80px;
}
.spacer-padding {
  padding-left: 42%;
}
.clickable-logo {
  cursor: pointer;
}
v-menu {
  z-index: auto;
}
</style>

<style lang="sass" scoped>
.v-input.expanding-search
  transition: max-width 0.5s
  cursor: pointer !important
  .v-input__slot
    &:before, &:after
      border-color: transparent !important
  &.closed
    max-width: 45px
    background-color: transparent
    .v-input__slot
      background: transparent !important
</style>
