<template>
  <div>
    <v-expansion-panels
      temporary
      right
      absolute
      class="button elevation-10"
      :class="buttonClasses"
      :aria-label="$t('solidaryButton.title')"
      @click.stop="drawer = !drawer"
    >
      <v-expansion-panel
        v-for="i in 1"
        :key="i"
        :aria-label="$t('solidaryButton.title')"
      >
        <v-expansion-panel-header
          color="warning"
          disable-icon-rotate
          :aria-label="$t('solidaryButton.title')"
          @click="modalOpen = !modalOpen"
        >
          <template #actions>
            <v-icon
              :aria-label="$t('solidaryButton.title')"
              color="white"
            >
              mdi-human-greeting-variant
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :aria-label="$t('solidaryButton.title')">
          <v-card class="elevation-0">
            <v-card-title class="text-subtitle-1">
              {{ $t("solidaryButton.title") }}
            </v-card-title>
            <v-card-text class="text-justify">
              {{ $t("solidaryButton.description") }}
            </v-card-text>
            <v-card-title class="text-subtitle-1 align-center justify-center">
              {{ $t("solidaryButton.helpAsPossible") }}
            </v-card-title>
            <v-card-actions class="d-flex align-center justify-center">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :aria-label="$t('solidaryButton.actions.sendGift')"
                    v-bind="attrs"
                    color="success"
                    rounded
                    v-on="on"
                    @click="sendDonate(), (giftOrEmailLinkClicked = true)"
                  >
                    <v-icon>mdi-gift-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("solidaryButton.actions.sendGift") }}
                </span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :aria-label="$t('solidaryButton.actions.sendEmail')"
                    v-bind="attrs"
                    color="error"
                    rounded
                    dark
                    class="ml-4"
                    v-on="on"
                    @click="(giftOrEmailLinkClicked = true), (dialog = true)"
                  >
                    <v-icon>mdi-email-heart-outline</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("solidaryButton.actions.sendEmail") }}
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <EmailModal
      :dialog="dialog"
      @close="dialog = false"
    />
    <DonateModal
      :dialog-donate="dialogDonate"
      @close="dialogDonate = false"
    />
  </div>
</template>

<script>
import DonateModal from "./Modals/DonateModal.vue";
import EmailModal from '@/components/Modals/EmailModal.vue';

export default {
    components: {
        EmailModal,
        DonateModal,
    },
    data() {
        return {
            dialog: false,
            dialogDonate: false,
            isExpanded: false,
            isAnimating: true,
            modalOpen: false,
            giftOrEmailLinkClicked: false,
            panel: null,
        };
    },
    computed: {
        buttonClasses() {
            return {
                "scale-transition":
          !this.modalOpen && !this.giftOrEmailLinkClicked ? true : false,
                "scale-transition--active":
          this.isExpanded && !this.modalOpen && !this.giftOrEmailLinkClicked,
            };
        },
    },
    watch: {
        drawer(newValue) {
            if (!newValue) {
                this.panel = -1;
                this.modalOpen = !this.modalOpen;
            }
        },
    },
    mounted() {
        this.startSizeAnimation();
    },

    methods: {
        startSizeAnimation() {
            setInterval(() => {
                if (this.isAnimating) {
                    this.isExpanded = !this.isExpanded;
                }
            }, 600); // Alterna a cada 600ms

            setInterval(() => {
                this.isAnimating = false;
                this.isExpanded = false;
                setTimeout(() => {
                    this.isAnimating = true;
                }, 5000); // Aguarda 5 segundos antes de retomar
            }, 10000); // Executa o loop por 10 segundos (5 segundos + 5 segundos)
        },
        sendDonate() {
            this.dialogDonate = true;
        },
    },
};
</script>


<style>
.button {
  align-items: center;
  overflow: hidden;
  width: auto;
  max-height: 450px;
  max-width: 300px;
  -webkit-transition: max-width 0.7s;
  transition: max-width 0.7s;
  -webkit-transition: max-height 0.7s;
  transition: max-height 0.7s;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.scale-transition {
  transition: transform 0.5s; /* Duração da transição */
}

.scale-transition--active {
  transform: scale(1.2); /* Tamanho desejado */
}
</style>