export default {
    methods: {
        isMobileDevice() {
            return (
                typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('Mobile') !== -1 ||
        this.isSmallScreenResolution()
            );
        },
        isSmallScreenResolution() {
            return window.innerWidth < 1300;
        },
        getPersonPhoto(fileName, isPope) {
            if (isPope) {
                return `https://hortussanctorumblob.blob.core.windows.net/popephotos/${fileName}`
            }
            return `https://hortussanctorumblob.blob.core.windows.net/saintsphotos/${fileName}`
        },
        getPopeCoatOfArms(fileName) {
            return `https://hortussanctorumblob.blob.core.windows.net/popecoatofarms/${fileName}`
        },
        getBlogImage(fileName) {
            return `https://hortussanctorumblob.blob.core.windows.net/blogimages/${fileName}`
        },
        getPhotoLinkError(isMale, coatOfArms) {
            if (coatOfArms) {
                return 'unknown-coat-of-arms.png'
            } else if (isMale) {
                return 'unknown_person_male.jpg'
            } else {
                return 'unknown_person_female.jpg'
            }
        },
        getLazyPhoto(isMale) {
            if (isMale) {
                return '../assets/unknown_person_male.jpg'
            } else {
                return '../assets/unknown_person_female.jpg'
            }
        },
        truncateDescription(description) {
            if (!description || description.length <= 100) {
                return description;
            } else {
                return description.substring(0, 200) + "...";
            }
        },
        getsiteLogo() {
            return 'https://hortussanctorumblob.blob.core.windows.net/common/candle-header.png'
        }
    },
};
